body {
  margin: 0;
  padding: 0;
}

.PaymentForm {
  font-family: sans-serif;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

}

.StripeElement {
  margin: 15px auto;
  padding: 15px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 25px;
  height: 50px;
  border-width: 1px;
  border-color: #717171;
  border-style: solid;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 550px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  
}

.bottom-container {
  width: 100%;
  background-color:green;
}

.btn-pay {
  display: block;
  width: 50%;
  border: none;
  background: #035DBD;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
  margin-left: 25%;
  margin-top: 20px;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}
