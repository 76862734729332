#floaty-container {
  height: 100%;
  width: 50%;
  position: absolute;
  right: calc(-50% + 36px);
  z-index: 99;
  pointer-events: all;
}

@media (min-width: 768px) and (max-width: 1380px) {
  #floaty-container {
    right: calc(-50% + 32px);
  }
}
.floaty {
  position: absolute;
  top: 60px;
  display: flex;
  flex-direction: column;
  color: black;
  z-index: 1;
  pointer-events: none;
  transition: all 0.2s ease-in;
}
.floaty * {
  pointer-events: all;
  transition: all 0.2s ease-in;
}
.floaty .floaty-boxes {
  position: relative;
  transition: transform 0.2s ease-in;
}
.floaty .floaty-boxes:first-child:after {
  content: "";
  width: 70%;
  position: absolute;
  left: 15%;
  bottom: 0px;
  height: 1px;
}
.floaty i {
  padding: 4px;
  margin-right: 0;
  color: black;
  font-size: 12px;
}

.floaty .floaty-link-box {
  width: 100%;
  display: flex;
  margin-top: 2px;
  cursor: pointer;
  border: 1px solid #2660ad;
}
.floaty .floaty-links-icon {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: right;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2660ad;
}

@media (min-width: 768px) and (max-width: 1380px) {
  .floaty .floaty-links-icon {
    width: 30px;
    height: 30px;
  }
}

.floaty.pink .floaty-links-icon {
  background-color: #2660ad;
}

.floaty .floaty-links-text {
  flex-grow: 2;
  line-height: 25px;
  color: #2660ad;
  width: 240px;
  padding: 5px;
  background: white;
  font-size: 14px;
}
@media (min-width: 768px) and (max-width: 1380px) {
  .floaty .floaty-links-text {
    line-height: 20px;
  }
}

.floaty .floaty-link-box:hover {
  transform: translate3d(-200px, 0, 0);
}

.floaty.all-links .floaty-link-box:hover .floaty-links-icon > i.fa {
  transform: rotate(180deg);
}

.floaty.all-links {
  top: initial;
  z-index: 2;
  bottom: 75;
}

.floaty.all-links.pink {
  top: 30px;
  z-index: 999;
}

.floaty.all-links .floaty-all-link-box {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #2660ad;
}

.floaty.all-links .floaty-links-icon,
.floaty.all-links .floaty-links-text.all-links-container {
  transform: translateX(-38px);
}

.floaty.all-links .floaty-link-box {
  align-items: flex-start;
  transform: translate3d(38px, 0, 0);
}
.floaty.all-links .floaty-link-box * {
  transition: none;
}

.floaty.all-links .floaty-all-link-box:hover {
  color: #2660ad;
}

.floaty.all-links .floaty-all-link-box:hover .floaty-all-links-icon i {
  color: #2660ad;
}

.floaty.all-links .floaty-all-link-box .floaty-all-links-icon i {
  padding: 14px 8px 14px 0;
  color: #2660ad;
}
