.react-datepicker {
  width: 100%;
}

.react-picker {
  width: 300px;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}

.react-datepicker__navigation--previous {
  background: url("left_arrow_round_edges.png") no-repeat;
  width: 25px;
  height: 25px;
  background-size: cover;
  border: none;
}

.react-datepicker_select-date-range {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.react-datepicker__navigation--next {
  background: url("right_arrow_round_edges.png") no-repeat;
  width: 25px;
  height: 25px;
  background-size: cover;
  border: none;
}

.react-datepicker__navigation--previous:focus,
.react-datepicker__navigation--next:focus {
  outline: none;
}

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--next--disabled {
  opacity: 0.2;
}

.react-datepicker__month-container {
  float: none;
}

.react-datepicker__current-month {
  padding: 15px;
  color: #014a99;
  text-transform: uppercase;
  font-size: 1.5em;
}

.react-datepicker__day-names {
  margin-top: 10px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day-name {
  color: #014a99;
  font-weight: bold;
}

.react-datepicker__day,
.react-datepicker__time-name {
  width: 100%;
  font-weight: bold;
}

.react-datepicker__day--today .react-datepicker__day--custom,
.react-datepicker__day--today .react-datepicker__day--custom:hover {
  color: #014a99;
}
.react-datepicker__day--custom:hover {
  background: #f0f0f0;
}

.legendWrapper {
  height: 12px;
}

.react-datepicker__day {
  vertical-align: top;
  outline: none;
}
.react-datepicker__day:hover {
  background: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:focus {
  outline: none;
}

.preview,
.auction {
  width: 100%;
  height: 5px;
  visibility: hidden;
}

.preview {
  border-top: 1px solid #6eb3ff;
  border-bottom: 1px solid #6eb3ff;
}

.auction {
  border-top: 1px solid #014a99;
  border-bottom: 1px solid #014a99;
}

.noAuction {
  width: 100%;
  height: 4px;
  visibility: visible;
  border: 1px solid #ccc;
}

.react-datepicker__day--highlighted-custom-preview .preview {
  visibility: visible;
  background: #82beff;
}

.react-datepicker__day--highlighted-custom-preview.react-datepicker__day--highlighted-custom-auction
  .preview {
  visibility: visible;
  background: #82beff;
}

.react-datepicker__day--highlighted-custom-auction .auction {
  visibility: visible;
  background: #014a99;
  margin-top: 1px;
}
.react-datepicker__day--highlighted-custom-preview.react-datepicker__day--highlighted-custom-auction
  .auction
  .react-datepicker__day--highlighted-custom-appointment {
  visibility: visible;
  background: #014a99;
  margin-top: 1px;
}

.react-datepicker__day--highlighted-custom-appointment {
  visibility: visible;
  border-bottom: 3px solid red;
}
.react-datepicker__day--highlighted-custom-preview .noAuction,
.react-datepicker__day--highlighted-custom-auction .noAuction {
  visibility: hidden;
}
.react-datepicker__day--highlighted-custom-auction .appointment {
  visibility: hidden;
}
/* overrides */

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  outline: none;
  font-weight: bold;
  background-color: #fff;
  color: #000;
}

.react-datepicker__day--in-range,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  outline: none;
  font-weight: bold;
  background-color: #fff;
  color: #000;
}

.react-datepicker__day--in-range:hover,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover {
  background-color: darken(#014a99, 5%);
}
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background: none;
}
